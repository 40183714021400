import React from "react";
import skycircuit_black from "../assets/skycircuit_black.jpeg";
import { Pages } from "./Pages";

const Menu = ["Home", "About", "Projects", "Internships"];

export const Navbar = () => {
  return (
    <div className="flex flex-row justify-between items-center">
      <div className="">
        <img className="w-[125px] h-[125px]" src={skycircuit_black} alt="" />
      </div>

      {/* <div className="flex flex-row gap-10 items-center">
        <div className="flex flex-row items-center">
          {
            Menu.map((page) => (
              <div key={page}>
                <Pages page={page} />
              </div>
            ))
          }
        </div>
        <div className="bg-gradient-to-b from-[#7137ab] to-[#280a3d] rounded-full px-10 py-2">
          Login
        </div>
      </div> */}
    </div>
  );
};
