import React from "react";
import drone3 from "../assets/drone3.png";

export const Internship = () => {
  return (
    <div className="mb-20">
      <div className="w-[600px] text-[50px] font-bold font-sans text-center mx-auto pt-5 mb-14">
        <span className="text-[#cc92f6]">Internship</span> Programs
      </div>
      <div className=" md:mx-20 sm:mx-8 md:px-20 sm:px-10 py-10 bg-gradient-to-b from-[#172327] to-[#000000] flex md:flex-row sm:flex-col-reverse justify-between rounded-[50px]">
        <div className="md:w-[50%] sm:w-[100%]">
          <div className=" text-[20px] font-sans mx-auto pt-5 pb-2 ">
            <span className="text-[#cc92f6]">• Build and Fly Drones:</span>{" "}
            Hands-on experience in constructing and operating drones.
          </div>
          <div className=" text-[20px] font-sans mx-auto pt-5 pb-2">
            <span className="text-[#cc92f6]">
              {" "}
              • Programming with Raspberry Pi:
            </span>{" "}
            Learn to program drones to perform complex tasks.{" "}
          </div>

          <div className=" text-[20px] font-sans mx-auto pt-5 pb-2">
            <span className="text-[#cc92f6]">
              {" "}
              • AI Integration:
            </span>{" "}
            AI Integration: Implement AI solutions to solve real-world problems.{" "}
          </div>
          <div className=" text-[20px] font-sans mx-auto pt-5 pb-2">
            Let's soar to new heights together! 🚀
          </div>
          <div className="w-[100px] text-xs text-center border-[1px] rounded-full py-2 my-5 border-[#cc92f6] ">
          Learn More
        </div>
        </div>
        <div className="md:w-[45%] sm:w-[100%] flex flex-col gap-10">
          <div>
            <div className="text-[30px] font-bold font-sans text-center mx-auto pt-5 text-[#cc92f6]">
              AI Drone
            </div>
            <div className="text-[30px] font-bold font-sans text-center mx-auto">
              Internship Program
            </div>
          </div>

          <div>
            <img src={drone3} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
