import React from 'react'

export const Mission = () => {
  return (
    <div className='pb-10'>
      <div className='text-[50px] font-bold font-sans pt-5 md:pl-32 sm:text-center'>
            Our <span className='text-[#cc92f6]'>Mission</span>
        </div>
        <div className='bg-[#282932] font-sans text-[22px] md:px-20 sm:px-10 text-justify py-6 my-14'>
        At SkyCircuit, our mission is to empower innovation by developing advanced technology solutions that propel businesses and individuals into a brighter future. We are dedicated to pushing the boundaries of what is possible, fostering creativity and driving positive change through our expertise in computer boards, drone technology and storage systems.
        </div>
    </div>
  )
}
