import React from 'react'
import {Navbar} from './Navbar'
import { Title } from './Title'
import { About } from './About'
import {Mission} from './Mission'
import {Goals} from './Goals'
import {Mentor} from './Mentor'
import {Team} from './Team'
// import {Reviews} from './Reviews'
import { Internship } from './Internship'

export const Home = () => {
  return (
    <div className='max-w-[90%]  mx-auto text-white'>
        <Navbar/>
        <Title />
        <About/>
        <Mission/>
        <Goals/>
        <Internship/>
        <Mentor/>
        <Team/>
        
    </div>
  )
}
