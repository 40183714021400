import './App.css';
import { Home } from './components/Home';
import {Footer} from './components/Footer'

function App() {
  return (
    <div className=" bg-black min-w-full  min-h-screen flex flex-col absolute mx-0 px-0">
      <div className='relative'>
      <Home/>
      <Footer/>
      </div>
      
    </div>
  );
}

export default App;
