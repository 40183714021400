import React from 'react'
import drone2 from '../assets/drone2.png'

export const About = () => {
  return (
    <div className='flex flex-col items-center mx-5 border-4 rounded-[30px] border-[#4e206f] mb-20'>
        <div className='w-[600px] text-[50px] font-bold font-sans text-center  pt-5 '>
            About <span className='text-[#cc92f6]'>SkyCircuit</span>
        </div>
        <div className='flex md:flex-row sm:flex-col justify-between px-[4%] my-6'>
            <div className='w-[36%] mx-auto'>
              <img src={drone2} alt="" />
            </div>
            <div className='md:w-[58%] sm:[80%] flex flex-col items-center justify-center text-center text-[30px] mx-auto'>
                <div>
                Welcome to SkyCircuit Innovations, your premier destination for cutting-edge drone technology. Whether you're an enthusiast, a professional or a business, we offer state-of-the-art drones and accessories to meet your needs. 
                </div>
                <div>
                Our user-friendly platforms, expert support, and commitment to quality ensure seamless operation, exceptional performance and durability.
                </div>
            </div>
        </div>
    </div>
  )
}
