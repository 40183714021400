import React from 'react'
import { Mentors } from '../Data/Mentors';

export const Mentor = () => {
  return (
    <div>
      <div className='w-[600px] text-[50px] font-bold font-sans text-center mx-auto pt-5 '>
             Our <span className='text-[#cc92f6]'>Mentor</span> 
        </div>
        <div className='mx-20 my-20 grid md:grid-cols-3 sm:grid-cols-1 gap-20 '>
          {
            Mentors.map((item, index) => {
              return (
                <div
                  key={index}
                >
                  <div className='border-[2px] border-[#bc84f4] rounded-[33px]'>
                    <div className=''><img className='rounded-t-[33px]' src={item.photo} alt="" /></div>
                    
                    <div className='text-[20px] font-medium font-sans text-center mt-4 mb-4'>{item.name}</div>
                   
                  </div>
                </div>
              );
            })}
        </div>
    </div>
  )
}
