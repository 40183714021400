import React from 'react'
import drone1 from '../assets/drone1.png'

export const Title = () => {
  return (
    <div className='flex flex-col items-center pb-20'>
        <div className='w-[600px] text-[50px] font-bold font-sans text-center mx-auto pt-5'>
            SkyCircuit <span className='text-[#cc92f6]'>Innovations Private</span>  Limited
        </div>
        <div>
          <img className='w-[500px] h-[500px]' src={drone1} alt="" />
        </div>
        <div className='w-[500px] text-[24px]  font-sans text-center text-[#828282] mx-auto pt-5'>
        Fly High, Capture More: Your Aerial Perspective Awaits!
        </div>
        <div className="bg-gradient-to-b from-[#7137ab] to-[#280a3d] rounded-full px-10 py-2 my-6">
          Explore
        </div>
    </div>
  )
}
