import React from "react";

export const Goals = () => {
  return (
    <div className="pb-20">
      <div className="text-[50px] font-bold font-sans pt-5 md:pr-32 sm:text-center text-right pb-14 ">
        Our <span className="text-[#cc92f6]">Goals</span>
      </div>
      <div className="flex flex-col">
        <div className="md:w-[70%] sm:w-[90%] md:ml-20 md:py-0 sm:py-5 mx-auto  border-2 border-[#bc84f4] rounded-xl  font-sans flex flex-row items-center px-8  gap-10 bg-[#282932] my-5">
          <div className="text-[100px] font-extrabold text-[#bc84f4]">1.</div>
          <div className="flex flex-col gap-2">
            <div className="text-[30px] font-bold text-[#bc84f4]">
              Innovation Leadership
            </div>
            <div>
              We aim to lead the way in innovation, setting new standards and
              introducing groundbreaking technologies that revolutionize
              industries and drive progress.
            </div>
          </div>
        </div>

        <div className="md:w-[70%] sm:w-[90%] border-2 border-[#bc84f4] rounded-xl font-sans flex flex-row items-center px-8 gap-10 bg-[#282932] md:ml-[24%] my-5 md:py-0 sm:py-5 mx-auto">
          <div className="text-[100px] font-extrabold text-[#bc84f4]">2.</div>
          <div className="flex flex-col gap-2">
            <div className="text-[30px] font-bold text-[#bc84f4]">
            Customer Satisfaction
            </div>
            <div>
            We are dedicated to provide tailored solutions that meet customer’s unique requirements, exceed expectations and deliver tangible value with utmost satisfaction.
            </div>
          </div>
        </div>

        <div className="md:w-[70%] sm:w-[90%] md:ml-20 md:py-0 sm:py-5 mx-auto  border-2 border-[#bc84f4] rounded-xl  font-sans flex flex-row items-center px-8  gap-10 bg-[#282932] my-5">
          <div className="text-[100px] font-extrabold text-[#bc84f4]">3.</div>
          <div className="flex flex-col gap-2">
            <div className="text-[30px] font-bold text-[#bc84f4]">
            Collaboration and Partnerships
            </div>
            <div>
            We actively seek collaborations and partnerships with like-minded organizations and individuals to foster a culture of knowledge sharing, synergy and collective growth.
            </div>
          </div>
        </div>

        <div className="md:w-[70%] sm:w-[90%] border-2 border-[#bc84f4] rounded-xl font-sans flex flex-row items-center px-8 gap-10 bg-[#282932] md:ml-[24%] my-5 md:py-0 sm:py-5 mx-auto">
          <div className="text-[100px] font-extrabold text-[#bc84f4]">4.</div>
          <div className="flex flex-col gap-2">
            <div className="text-[30px] font-bold text-[#bc84f4]">
            Sustainability and Responsibility            </div>
            <div>
            We aim to lead the way in innovation, setting new standards and introducing groundbreaking technologies that revolutionize industries and drive progress.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
