import React from 'react'
import skycircuit_white from '../assets/skycircuit_White.jpeg'
import {Links} from '../Data/Links'

export const Footer = () => {
  return (
    <div className='bg-white text-[#454545] pt-20 pb-10 pl-20 pr-20 flex flex-row'>
      <div className='w-[50%]'>
        <div>
          <img className='w-[120px]' src={skycircuit_white} alt="" />
        </div>
        
      </div>
      {/* <div className='w-[50%] grid grid-rows-3 grid-flow-col '>
          {
            Links.map((item, index) => {
              return (
                <div
                  key={index}
                >
                    <div className='text-sm font-medium font-sans '>{item.name}</div>
                </div>
              );
            })}
        </div> */}
      
    </div>
  )
}
