import React from 'react'
import {TeamMembers} from '../Data/TeamMembers'

export const Team = () => {
  return (
    <div className='pb-10'>
      <div className='w-[600px] text-[50px] font-bold font-sans text-center mx-auto pt-5 '>
             Our <span className='text-[#cc92f6]'>Team</span> 
        </div>
        <div className='mx-20 my-20 grid md:grid-cols-4 sm:grid-cols-1 gap-12 '>
          {
            TeamMembers.map((item, index) => {
              return (
                <div
                  key={index}
                >
                  <div className='border-[2px] border-[#bc84f4] rounded-[33px]'>
                    <div className=''><img className='rounded-[30px] w-[100%]' src={item.photo} alt="" /></div>
                    <div className='mt-2 mb-4'>
                    <div className='text-[20px] font-medium font-sans text-center'>{item.name}</div>
                    <div className='text-[#828282] text-center'>{item.text}</div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
    </div>
  )
}
