import Devesh from '../assets/Devesh.jpg'
import Tarun from '../assets/Tarun.jpeg'
import Aditya from '../assets/Aditya.png'
import Hitesh from '../assets/Hitesh.jpg'
import Atharva from '../assets/Atharva.jpg'
import Gaurav from '../assets/Gaurav.jpg'
import Gayatri from '../assets/Gayatri.png'
import Azhar from '../assets/Azhar.jpg'

export const TeamMembers = [
    {
      name: "Devesh Shravage",
      photo: `${Devesh}`,
      text: "CEO",
    },
    {
        name: "Tarun Suryavanshi",
        photo: `${Tarun}`,
        text: "CTO",
      },
      {
        name: "Aditya Shimpi",
        photo: `${Aditya}`,
        text: "COO",
      },
      {
        name: "Hitesh Mahajan",
        photo: `${Hitesh}`,
        text: "Lead AIML Engineer",
      },
      {
        name: "Atharva Bagul",
        photo: `${Atharva}`,
        text: "Lead R & D Engineer",
      },
      {
        name: "Gaurav Khalase",
        photo: `${Gaurav}`,
        text: "Full Stack Developer",
      },
      {
        name: "Gayatri Warkhede",
        photo: `${Gayatri}`,
        text: "UI/UX Designer",
      },
      {
        name: "Azhar Sayyed",
        photo: `${Azhar}`,
        text: "Drone Technitian",
      },
  ];
  