import rajawat_sir from '../assets/rajawat_sir.jpg'
import gayatri_mam from '../assets/gayatri_mam.jpg'
import col_sir from '../assets/col_sir.jpg'


export const Mentors = [
    {
      name: "Dr. Anand Singh Rajawat",
      photo: `${rajawat_sir}`,
    },
    {
        name: "Dr. Gayatri Phade",
        photo: `${gayatri_mam}`,
      },
      {
        name: "Col. Kashikar Sarang",
        photo: `${col_sir}`,
      },
      
  ];
  